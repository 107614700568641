import {
  inject,
  Injectable,
  PLATFORM_ID,
  Signal,
  StateKey,
  TransferState,
} from '@angular/core';
import { SsrCookieService } from 'ngx-cookie-service-ssr';
import { environment } from '../environments/environment';
import { createAPIClient } from '@shopware/api-client';
import { operations, Schemas } from '../api-types/storeApiTypes';
import {
  FilteredListState,
  filteredListStore,
  FilterState,
} from './filteredListStore';
import { ListState, listStore } from './listStore';
import { apiStore } from './api.store';

function createContextToken(cookies: SsrCookieService) {
  const cookie = cookies.get('sw-context-token');
  if (cookie) {
    return cookie;
  }

  const newCookie = Math.random().toString(36);
  cookies.set('sw-context-token', newCookie);

  return newCookie;
}

export interface FilteredResult<Result> {
  elements: Result[];
  total: number;
  aggregations: FilteredAggregation;
}

export interface FilteredAggregation {
  stock: {
    buckets: {
      key: string;
      count: number;
    }[];
  };
  filters: {
    buckets: {
      key: string;
      count: number;
      options: {
        entities: {
          id: string;
          groupId: string;
          name: string;
          position: number;
        }[];
      };
    }[];
  };
  properties: {
    buckets: {
      key: string;
      options: {
        buckets: {
          count: number;
          key: string;
        }[];
      };
    }[];
  };
}

@Injectable({
  providedIn: 'root',
})
export class StoreApiService {
  private readonly cookies = inject(SsrCookieService);
  private readonly transferState = inject(TransferState);
  private readonly platformId = inject(PLATFORM_ID);

  accessToken = environment.accessToken;
  contextToken = createContextToken(this.cookies);

  apiClient = createAPIClient<operations>({
    contextToken: this.contextToken,
    accessToken: this.accessToken,
    baseURL: environment.baseUrl + '/store-api',
  });

  constructor() {
    this.apiClient.hook('onContextChanged', (newContextToken) => {
      if (newContextToken) {
        this.cookies.set(
          'sw-context-token',
          newContextToken,
          365,
          '/',
          undefined,
          true,
          'Lax',
        );
      }
    });
  }

  createApiStore<T>(key: StateKey<T>, val: () => Promise<T>) {
    const existing = this.transferState.get(key, null);
    if (existing) {
      return apiStore(Promise.resolve(existing));
    } else {
      return apiStore(
        val().then((r) => {
          //this.transferState.set(key, r);
          return r;
        }),
      );
    }
  }

  createFilteredStore<Filter extends FilterState, Result>(
    key: StateKey<FilteredListState<Filter, Result>>,
    filterStore: Signal<Filter>,
    fetch: (
      filter: Filter,
      skip: number,
      signal: AbortSignal,
    ) => Promise<FilteredResult<Result>>,
  ) {
    return filteredListStore(
      key,
      this.platformId,
      this.transferState,
      filterStore,
      fetch,
    );
  }

  createListStore<Result>(
    key: StateKey<ListState<Result>>,
    fetch: (
      abort: AbortSignal,
    ) => Promise<{ elements: Result[]; total: number }>,
  ) {
    return listStore(key, this.platformId, this.transferState, fetch);
  }

  getProducts(
    criteria: Schemas['Criteria'] & { 'total-count-mode'?: 'exact' },
    signal: AbortSignal,
  ): Promise<FilteredResult<Schemas['Product']>> {
    return this.apiClient
      .invoke('readProduct post /product', {
        body: criteria,
        fetchOptions: { signal },
      })
      .then((r) => {
        return {
          elements: r.data.elements as Schemas['Product'][],
          total: r.data.total ?? 0,
          aggregations: r.data.aggregations as any,
        };
      });
  }
}
